* {
  font-family: "DM Serif Display", serif;
  font-family: "Open Sans", sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

.ch-container {
  width: 100%;
}

/* Page main header and loggin info */
.ch-header {
  height: 60px;
  display: flex;
}

.app-logo {
  height: 60px;
  color: #fff;
}

.app-logo img {
  width: auto;
  height: 60px;
}

.ch-header .blank {
  width: 60%;
}

.ch-header .col-4 {
  width: 20%;
}

.notifications button {
  border: none;
  background-color: transparent;
  background: url(logo/notification.png);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin-top: 15px;
  margin-right: 20px;
}

.notifications button span {
  border-radius: 10px;
  padding: 4px 6px;
  background-color: #c0242f !important;
  margin-top: -10px;
  display: table;
  margin-left: 7px;
  font-size: 10px;
}

.user-dropdown {
  margin-top: 10px !important;
}

.user-dropdown button {
  text-align: left;
  border-radius: 3px;
}

.application_guide button {
  border: 1px solid #c5c5c5;
  border-radius: 15px;
  background-color: transparent;
  background: url(logo/icons8-info-104.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 21px;
  margin-top: 18px;
  padding: 0;
  cursor: pointer;
}

.application_guide button:hover {
  border-color: #666;
}

.logged-user-info {
  position: absolute;
  right: 20px;
}

.logged-users .row {
  margin: 0;
}

.logged-users .dropdown-menu {
  width: 300px;
  max-width: 360px;
  font-size: 13px !important;
  padding: 5px;
  background-color: #3e3e3e !important;
}

.logged-users .dropdown-menu a:hover {
  background-color: #2d4ad2;
}

.logged-users .dropdown-menu input {
  font-size: 12px;
  white-space: nowrap;
  margin-top: 10px;
  border-radius: 3px;
  padding: 5px;
}

.logged-users .dropdown-menu a {
  color: #f7f7f7 !important;
  background-color: #3e3e3e !important;
}

.logged-users .dropdown-menu a:hover {
  background-color: #2d4ad2 !important;
}

.logged-users .dropdown-toggle::after {
  position: absolute;
  right: 5px;
}

.logged-users .dropdown-toggle {
  font-size: 13px;
  font-weight: 200;
}

.user-list.form-control {
  border: 1px solid #4972bd !important;
  padding: 4px 16px 4px 4px;
  margin: 0px 12px 0px 12px;
  width: 90%;
  font-size: 12px !important;
}

/* Main navigation and status info */
.ch-main-nav {
  height: 44px;
  background-color: #ececec;
  display: flex;
}

.week-nav {
  margin-left: 20px;
  width: 55%;
  display: flex;
}

.prev-week,
.next-week {
  min-width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  transition: all ease 0.3s;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: auto;
  border: 0;
}

.prev-week {
  background: url(/src/logo/arrow-left.png);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
}

.next-week {
  background: url(/src/logo/arrow-right.png);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
}

.prev-week:hover,
.next-week:hover {
  background-color: #ccc;
  transition: all ease 0.3s;
}

.next-week:disabled,
.prev-week:disabled,
.week-nav .date:disabled {
  background-color: #b0b0b0 !important;
  color: white;
  transition: all ease 0.3s;
  cursor: default;
}

.week-nav .date {
  margin: 7px 15px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ececec;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all ease 0.3s;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 17px;
}

.week-nav .date:hover {
  cursor: pointer;
  border: 1px solid #333;
}

.week-no {
  font-size: 16px;
  color: #353535;
  height: 40px;
  line-height: 44px;
  margin-left: 40px;
  text-transform: none;
  font-weight: 400;
  white-space: nowrap;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: 120px;
}
.unfreez {
  margin-left: 25px;
  margin-top: 10px;
  padding: 1px 10px 2px 10px;
  background-color: #333333 !important;
  color: white;
  font-size: 12px;
  border: none;
  white-space: nowrap;
  height: 24px;
}

.unfreez a {
  text-decoration: none !important;
}

.unfreez:hover {
  background-color: #0e0d0d !important;
  color: white;
  font-size: 11px;
  text-decoration: underline;
}

.unfreez:disabled {
  background-color: #b0b0b0 !important;
  color: white;
}
.unfreeze-header {
  margin-bottom: 40px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.unf-date {
  width: fit-content;
  padding: 3px 12px;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
}

.ok-btn {
  background-color: #0c7465;
}

.unfreeze-ticket {
  border: 2px solid;
  border-color: #1266a2;
  padding: 20px;
  border-radius: 5px;
  background-color: #f8f8f8;
  font-weight: 600;
  font-size: 15px;
}

.unfreeze-ticket .row {
  margin: 0 !important;
}

.unfreeze-popup .modal-dialog {
  width: 400px !important;
  max-width: 400px !important;
}

.select-type {
  margin-bottom: 20px;
}

.unfreeze-popup .dropdown button {
  text-align: left;
  margin: 0;
  font-size: 13px;
  border: 1px solid #ccc !important;
}

.unfreeze-popup .dropdown,
.unfreeze-popup .textarea {
  margin: 0;
}

.unfreeze-popup .row {
  margin-bottom: 20px;
}

.unfreeze.form-control {
  border: 1px solid #4972bd !important;
  padding: 4px 16px 4px 4px;
  margin: 0px 12px 0px 12px;
  width: 90%;
  font-size: 12px !important;
}

.unfreeze-link {
  /* margin-right: 240px !important; */
  font-size: 12px;
  color: #2d4ad2;
  font-weight: normal;
  margin-bottom: 5px;
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid #2d4ad2;
  border-radius: 15px;
  padding: 1px 10px 2px;
}

.unfreeze-link a {
  text-decoration: none !important;
}

.unfreeze-link:hover {
  /* background-color: #0e0d0d !important; */
  color: #2d4ad2;
  font-size: 11px;
  font-weight: 600;
  text-decoration: underline;
}

.snow-btn {
  margin-top: 5px;
  padding: 5px 5px 5px 5px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #333;
  color: #fff;
  background-color: #333;
  margin-left: 149px !important;
}

.snow-btn:hover {
  /* border: 1px solid #333; */
  color: rgb(244, 232, 232);
  background: linear-gradient(0deg, #403fe2, #4a7bb6) !important;
}

.snow-close-btn {
  margin-top: 5px;
  padding: 5px 5px 5px 5px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #333;
  color: #fff;
  background-color: #333;
  margin-left: 207px !important;
}

.snow-close-btn:hover {
  /* border: 1px solid #333; */
  color: rgb(244, 232, 232);
  background: linear-gradient(0deg, #403fe2, #4a7bb6) !important;
}

.nav-actions {
  margin-left: auto;
  margin-top: 5px;
}

.nav-actions button {
  border: 2px solid #363636 !important;
  background-image: none !important;
  border-radius: 5px;
  transition: background-color ease 0.3s;
  font-size: 12px;
  margin-right: 10px;
  white-space: nowrap;
  padding: 7px 15px;
  font-weight: 600;
}

.nav-actions .saveDraft {
  background-color: #ebebeb !important;
  color: #3a3a3a;
}

.nav-actions .submit {
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  color: #ffffff;
}

.nav-actions .submit:hover,
.saveDraft:hover {
  background-color: #222 !important;
  color: #ebebeb !important;
}

.nav-actions .copy_previous_week {
  margin-right: 20px;
  font-size: 12px;
  color: #2d4ad2;
  font-weight: normal;
  margin-bottom: 5px;
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid #2d4ad2;
  border-radius: 15px;
  padding: 1px 10px 2px;
}

.nav-actions .copy_previous_week:hover {
  color: #2d4ad2 !important;
}

/* Acction button section -----------*/
.ch-actions {
  height: 40px;
  background-color: #fff;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.ch-project-name {
  line-height: 40px;
}

.ch-main-actions {
  margin-left: auto;
  line-height: 40px;
}

/* Dynamic data contaciner block -------------*/
.ch-dynamic-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.ch-task-content {
  height: 100vh;
  width: inherit;
  padding: 0;
}

.ch-panel-section {
  height: 100vh;
  width: 340px;
  padding: 0;
  background-color: #ececec;
}

.ch-panel-section .accordion-item {
  border-bottom: 1px solid #525252;
}

.accordion-header button {
  padding: 0px 10px 0 0;
  color: #fff;
  font-size: 13px;
  white-space: nowrap;
}

.alert-header button {
  background-color: #9b3434;
}

.add-task-header button {
  background-color: #207c36;
}

.sel-task-header button {
  background-color: #213e87;
}

.alert-header button:not(.collapsed) {
  background-color: #9b3434;
}

.add-task-header button:not(.collapsed) {
  background-color: #207c36;
}

.sel-task-header button:not(.collapsed) {
  background-color: #213e87;
}

.accordion-header button:hover {
  color: #fff;
}

.ch-panel-section .accordion-button:not(.collapsed) {
  color: #eaeaea;
  box-shadow: #4c4c4c 0px 1px;
}

.accordion-button:not(.collapsed)::after {
  color: #333;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ch-panel-section .accordion-body {
  background-color: #ececec;
  color: #222;
  font-size: 14px;
  padding: 10px;
  white-space: nowrap;
  height: calc(100vh - 205px);
}

.accordion {
  height: calc(100vh - 105px);
  transition: all 0s;
}

.accordion-flush .accordion-item .accordion-collapse.show {
  height: calc(100vh - 205px);
}

.ch-panel-section .accordion-flush .accordion-collapse {
  background-color: #363636;
  border-radius: 0 !important;
}

.ch-accordian-header-icon {
  width: 34px;
  height: 34px;
  margin-right: 10px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.ch-accordian-header-icon.ch-alert {
  background-image: url(/src/logo/notification-white.png);
}

.ch-accordian-header-icon.add-task {
  background-image: url(/src/logo/add-task.png);
}

.ch-accordian-header-icon.selected-task {
  background-image: url(/src/logo/select-task.png);
}

.alert-list {
  list-style: none;
  padding: 0;
  font-size: 13px;
  white-space: nowrap;
}

.alert-list li {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #acacac;
  color: #333;
}

.alert-list .alert-point {
  background-color: #c1392b;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 7px;
  border-radius: 10px;
  padding: 0 !important;
}

.alert-list li.no-alert {
  background-color: #0c7465;
  color: #fff;
  margin: auto;
  text-align: center;
  cursor: default;
}

.ch-comm-notification
{
  height: auto !important;
  background-color: #aa1923;
  padding-left: 1%;
  width: 100%;
  /* line-height: 40px; */
  color: #fff;
  font-size: 12px;
  /* text-align: justify; */
  width: 100%;               /* Makes the div take up the full width of the container */
  max-width: 100%;          /* Optional: Max width for the div */
  padding-top: 6px;             /* Optional: Add padding inside the div */
  margin: 0 auto;            /* Center the div horizontally */
  white-space: nowrap !important;     /* Ensures that long words are wrapped */
  overflow-wrap: break-word; /* Breaks the words when they are too long */
  white-space: normal;       /* Ensures text wraps onto the next line */
}



.project-type-btn {
  width: 100%;
  margin-bottom: 20px;
}

.project-type-btn button {
  width: 50%;
  font-size: 12px;
  color: #222;
  border: 2px solid #4972bd;
  background-color: transparent;
  white-space: nowrap;
  outline: none !important;
}

.project-type-btn button:active {
  background-color: #4972bd;
}

.project-type-btn button:hover,
.project-type-btn button.active:hover {
  background-color: #4972bd;
  color: #fff;
}

.project-type-btn button.active {
  border: 2px solid #4972bd;
  background-color: #4972bd;
  color: #fff;
}

.ch-non-project .notify-label,
.ch-un-assigned .notify-label {
  font-size: 12px;
  font-weight: 100;
  color: #444;
  white-space: nowrap;
  font-style: italic;
}

.ch-non-project .dropdown,
.ch-un-assigned .dropdown {
  margin-bottom: 20px;
}

.ch-un-assigned a {
  color: #333;
  font-size: 11px;
  font-weight: 600;
  white-space: break-spaces;
  text-decoration: none;
}

.ch-un-assigned a:hover {
  color: #222;
}

.ch-un-assigned .copy-unAssign-Task {
  border-radius: 5px;
  margin-bottom: 15px;
}

.ch-un-assigned .copy-unAssign-Task > button {
  padding: 3px 8px 5px 8px;
  font-size: 11px;
  text-align: center;
  border: 1px solid #666;
  border-radius: 5px;
}

.ch-un-assigned .copy-unAssign-Task > button:hover {
  /* cursor: pointer; */
  background-color: #fff;
  /* color:#2d4ad2 !important; */
  /* text-decoration: underline; */
  border: 1px solid #666;
}

.ch-non-project text,
.ch-un-assigned text {
  font-size: 12px;
  font-weight: 600;
  color: #333;
  white-space: nowrap;
}

.ch-non-project .dropdown button,
.ch-un-assigned .dropdown button {
  background-color: #fff;
  border-radius: 3px;
  font-size: 13px;
  color: #222;
  text-align: left;
  margin: 2px 0 !important;
  border: 1px solid #444 !important;
  white-space: nowrap;

  /* width: 100%; */
  max-width: 250px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.ch-non-project .dropdown button:after,
.ch-un-assigned .dropdown button:after {
  margin-top: 5%;
  position: absolute;
  right: 3%;
  top: 4%;
}
.ch-un-assigned .dropdown-menu,
.ch-non-project .dropdown-menu {
  background-color: #fff;
  border-color: #4a4a4a;
}

.ch-un-assigned .dropdown-menu input,
.ch-non-project .dropdown-menu input {
  color: #3e3e3e !important;
  background-color: #f7f7f7 !important;
  font-size: 11px;
  height: 20px;
  white-space: nowrap;
}

.ch-un-assigned .dropdown-menu a,
.ch-non-project .dropdown-menu a {
  color: #333 !important;
  background-color: #fff;
  border-color: #4a4a4a;
  font-weight: normal;
  display: block;
  white-space: collapse;
  min-height: 1.2em;
  font-size: 11px;
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #eee;
}

.ch-un-assigned .dropdown-menu a:hover,
.ch-non-project .dropdown-menu a:hover {
  background-color: #e9e9e9 !important;
}

.ch-non-project input,
.ch-un-assigned input,
.ch-rem-hrs input {
  background-color: #fff;
  border-radius: 3px;
  font-size: 13px;
  color: #222;
  text-align: left;
  margin: 2px 0 10px 0 !important;
  border: 1px solid #444 !important;
  height: 32px;
  padding-left: 10px;
  white-space: nowrap;
}

.ch-non-project button.btn,
.ch-un-assigned button.btn {
  font-size: 12px;
  border: 2px solid #666;
  white-space: nowrap;
}

.unassigned-task-search.form-control {
  border: 1px solid #4972bd !important;
  padding: 5px 16px 5px 16px;
  margin: 0px 12px 0px 10px !important;
  width: 90%;
  font-size: 12px !important;
}

.ch-task-container {
  margin-top: 2px;
}

.ch-row {
  width: 100%;
  display: flex;
}

.ch-time-detail-col {
  width: 33.33%;
  padding-left: 2%;
}

.ch-col8 {
  width: 8.333333%;
  text-align: center;
  padding: 0.4%;
}

.ch-time-detail-col,
.ch-col8 {
  display: inline-block;
  vertical-align: top;
  padding-top: 1.2%;
}

.form-check .form-check-input {
  border: 2px solid #9e9e9e;
}

.form-check-input:checked[type="checkbox"] {
  background-color: #9e9e9e;
  border: 1px solid #9e9e9e;
}

.form-check-label {
  color: #000;
}

.week-number:hover {
  background-color: #eeeeee;
}

.week-number {
  border-radius: 5px;
  padding: 7px;
  width: 76px;
  margin: auto;
  background: linear-gradient(0deg, #e8e8e8, #f8f8f8);
}

.week-number .date {
  font-size: 26px;
  color: #333;
  font-weight: 600;
  white-space: nowrap;
}

.week-number .day {
  font-size: 10.5px;
  color: #535353;
  margin-top: 0px;
  margin-left: 2%;
  margin-right: 2%;
  white-space: nowrap;
}

.week-number.today,
.week-number.today .day,
.week-number.today .date {
  color: #ffffff;
}

.week-number.today {
  background: linear-gradient(0deg, #403fe2, #4a7bb6);
}

.week-number.Holiday_Full_Day,
.week-number.Holiday_Half_Day {
  background: linear-gradient(0deg, #9b2b2a, #bc3534);
}

.week-number.Holiday_Full_Day .date,
.week-number.Holiday_Full_Day .day,
.week-number.Holiday_Half_Day .date,
.week-number.Holiday_Half_Day .day {
  color: #fff;
}

.week-number.Holiday_Half_Day.today,
.week-number.Holiday_Full_Day.today {
  background: linear-gradient(0deg, #403fe2, #4a7bb6);
  border-left: 2px solid #bc3534;
  border-right: 2px solid #bc3534;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.week-number.Leave_Full_Day .leave-status {
  width: 100%;
  background-color: #ef9b39;
}

.week-number.Leave_Half_Day .leave-status {
  width: 50%;
  background-color: #ef9b39;
}

.week-number.Leave_Hourly .leave-status {
  width: 10%;
  background-color: #ef9b39;
}

.leave-status {
  height: 3px;
  width: 0%;
  background-color: transparent;
  margin: 5px auto 0;
  border-radius: 5px;
}

.week-hour {
  font-size: 12px;
  margin-top: 10px;
  color: #333;
  border-radius: 3px;
  white-space: nowrap;
}

.week-hour.active {
  background-color: #e24131;
  color: #fff;
}

.ch-seperator {
  clear: both;
}

.task-block {
  margin-top: 0px;
}
.task-block div.ch-row {
  height: calc(99vh - 245px);
  overflow-y: auto;
}
.total-hr label {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  margin-bottom: 0px;
  display: block;
  white-space: nowrap;
  font-weight: 600;
}

.hour-count {
  font-size: 28px;
  color: #e24131;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
  margin-right: 7px;
}

.total-hr span {
  font-size: 13px;
  color: #222;
  display: inline-block;
  white-space: nowrap;
  font-weight: 600;
}

.ch-time-table {
  width: 100%;
  font-size: 12px;
}

.ch-time-table tbody {
  width: 100%;
}

.ch-time-table tbody tr {
  display: table;
  width: 100%;
}

.ch-time-table .project-name {
  background-color: #363636;
  color: #eaeaea;
}

.ch-time-table .project-name td {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.ch-time-table tbody tr td {
  width: 8.333333%;
  line-height: 32px;
}

.ch-time-table tbody tr td tr td {
  width: auto;
}

.ch-time-table tbody tr td tr td {
  width: auto;
}

.ch-time-table tbody tr td:first-child {
  width: 33.333333%;
  text-align: left;
  padding-left: 10px;
  white-space: nowrap;
  font-size: 13px;
}

.ch-time-table tbody tr td tr td:first-child {
  width: 40px;
}

.ch-time-table tbody tr.task_data td {
  border-right: 1px solid #ebebeb !important;
  border-bottom: 1px solid #ebebeb !important;
  vertical-align: middle;
  /* padding: 2px; */
}

.ch-time-table tbody tr.task_data td tr td {
  border: none !important;
}

.ch-time-table tbody tr.task_data td tr td.task-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.ch-time-table tbody tr.task_data td input {
  border: none;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  outline: none;
  font-size: 13px;
}

.ch-time-table tbody tr.task_data td input:hover {
  border: 1px dotted #aeaeae;
}

.ch-time-table tbody tr.task_data td input:disabled {
  background-color: #eeeeee !important;
}

.ch-time-table tbody tr.task_data td.ch-time-row-total {
  padding-right: 20px;
  text-align: right;
  border: none !important;
  white-space: nowrap;
}

.add-non-project {
  margin-left: 20px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: none;
  position: relative;
  line-height: 0px;
  background: linear-gradient(0deg, #403fe2, #4a7bb6) !important;
  color: #222;
}
/* .tooltip[data-popper-reference-hidden="true"] {
  visibility: hidden;
  pointer-events: none;
} */
.time_type_status_tooltip {
  background: linear-gradient(0deg, #403fe2, #4a7bb6);
  color: #fff;
  border: 1px solid #403fe2;
  border-radius: 5px;
  font-size: 10px !important;
}

.time_status_tooltip {
  background: linear-gradient(0deg, #403fe2, #4a7bb6);
  color: #fff;
  border: 1px solid #403fe2;
  border-radius: 5px;
  font-size: 10px !important;
}
.leaveHoliday_status_tooltip {
  background: linear-gradient(0deg, #403fe2, #4a7bb6);
  color: #fff;
  border: 1px solid #403fe2;
  border-radius: 5px;
  font-size: 9px !important;
}
.popover-arrow {
  border-color: #4a7bb6 !important;
  background-color: #4a7bb6 !important;
}

/* time slot input */
.timeSlot {
  width: 100%;
  background-color: #fff !important;
  white-space: nowrap;
  text-align: center !important;
}

/* Submitted */
.timeSlot.U {
  background: url(../src/logo/time-submit.png);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 1px 1px;
}

/* Approved */
.timeSlot.A {
  background: url(../src/logo/time-approve.png);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 1px 1px;
}

/* Reject */
.timeSlot.R {
  background: url(../src/logo/time-reject.png);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 1px 1px;
}

/* Save */
.timeSlot.S {
  /* background-color:  #fff !important; */
  /* background-color: transparent; */
}

/* timeEntryPendingStatus */
.timeSlot.PS {
  background: url(../src/logo/time-inprogress.png);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 1px 1px;
}

.timeSlot.B {
  background-color: rgb(220, 220, 220);
}

.ch-time-table tbody tr.task_data td input.timeSlot.quater_freeze,
.ch-time-table tbody tr.task_data td input.timeSlot.quater_freeze:disabled,
.ch-selected-info input.timeSlot.quater_freeze,
.ch-selected-info input.timeSlot.quater_freeze:disabled {
  background-color: #edf1f5 !important;
  color: #64a5f2;
  font-weight: 700;
}

.ch-time-table tbody tr.task_data td input.timeSlot.week_freeze,
.ch-time-table tbody tr.task_data td input.timeSlot.week_freeze:disabled,
.ch-selected-info input.timeSlot.week_freeze,
.ch-selected-info input.timeSlot.week_freeze:disabled {
  border-color: #64a5f2;
  border-radius: 5px;
}
.ch-selected-info input.timeSlot.week_freeze:disabled {
  border-color: #64a5f2;
  border: 1px solid #64a5f2;
}
/* .timeSlot.quater_freeze {
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 0 0;
  border: 2px dotted #4193ca !important;
  border-radius: 3px;
} */

/* .timeSlot.week_freeze {
  background-size: 15% !important;
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  background: url(/src/logo/wfreeze.png);
} */

/* Weekly Time Time along with Submitted Time
.timeSlot.week_freeze.U {
  background-size: 15% !important;
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  background: url(/src/logo/pending_freeze2.png);
}

/*Weekly Time Time along with Approved Time */
/* .timeSlot.week_freeze.A {
  background-size: 15% !important;
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  background: url(/src/logo/submit_freeze2.png);
} */

/*Weekly Time Time along with Rejected Time */
/* .timeSlot.week_freeze.R {
  background-size: 15% !important;
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  background: url(/src/logo/reject_freeze2.png);
} */

.timeSlot.active {
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 0 0;
  border: 1px dotted #616365 !important;
  border-radius: 3px;
  background-color: #fff;
}

/* Popup Styling------ ------------*/
div.modal-width {
  width: 920px !important;
  max-width: 920px !important;
  font-size: 13px;
}

div.modal-header {
  font-size: 15px;
  font-weight: 500;
  background-color: #222;
  color: #fff;
  padding: 10px 20px;
}

.modal-header .btn-close {
  background-color: #fff;
  width: 8px;
  background-size: 12px;
  height: 8px;
  opacity: 1;
}

div.modal-title {
  margin: 10px 0 0 0;
  font-weight: 500;
}

div.modal-title .col-2 {
  text-align: center;
}

.modal-content.loading {
  display: block;
  cursor: wait;
  background-color: gainsboro;
}

div.modal-body {
  text-align: left;
}

button.modal-button {
  margin-top: 5px;
  padding: 2 5 2 5;
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #333;
  color: #fff;
  background-color: #333;
}

button.modal-button:hover {
  /* border: 1px solid #333; */
  color: rgb(244, 232, 232);
  background: linear-gradient(0deg, #403fe2, #4a7bb6) !important;
}

.task-done-warning .toast-header,
.error-message-warning .toast-header {
  font-size: 15px;
  font-weight: 500;
  background-color: #222;
  color: #fff;
  padding: 5px 10px;
}

.btn-close {
  --bs-btn-close-bg: url(/src/logo/close.png);
}

.task-done-warning .modal-width {
  width: 550px;
  max-width: 550px !important;
}

.task-done-warning .modal-body,
.task-done-warning .toast-body {
  margin-top: 10px;
}
.task-done-warning .modal-button,
.error-message .modal-button {
  font-size: 11px;
}
.task-done-warning .modal-footer,
.error-message .modal-footer {
  margin: 5px;
}
.toast {
  background-color: transparent;
}

.toast-header .btn-close {
  margin-right: 0px;
  border: 1px solid #333;
  width: 20px;
  border-radius: 25px;
  background: url(/src/logo/close.png);
  background-size: 38px;
  background-repeat: no-repeat;
  background-position: center;
  --bs-btn-close-bg: url();
}
.error-message .modal-body .row {
  border: 1px solid #cecece;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
}

.error-message .modal-dialog.modal-width {
  /* width: 720px;
  max-width: 720px !important; */
}

.alert-ico {
  background: url(/src/logo/alert.png);
  background-size: 24px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.warning-ico {
  background: url(/src/logo/alert.png);
  background-size: 24px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Right panel ------- just*/
.mb-2 {
  max-width: 200px !important;
}

.wip-dropdown {
  width: 52px !important;
  height: 17px;
  font-size: 9px;
  border-radius: 2px;
  vertical-align: text-bottom;
  margin-right: 5px !important;
  margin-left: -10px !important;
  padding: 0 !important;
  opacity: 0.6;
}

/* project dropdown list----------------*/
.dropdown {
  display: block;
  font-weight: 100;
}

.dropdown-toggle {
  width: 100%;
  max-width: 360px;
  margin: 2%;
  text-overflow: ellipsis;
  overflow: hidden;
  border: transparent !important;
}

.dropdown-toggle::after {
  margin-top: 7px;
  float: right;
}

.projetlist_dropdown.dropdown {
  display: block;
  width: 90%;
  border: 1px solid #e0dede;
  border-radius: 3px;
  margin: 20px 0;
}

.projetlist_dropdown.dropdown button {
  font-size: 12px;
  padding: 2%;
  margin: 0;
  white-space: nowrap;
  border: none !important;
  background-color: #fff;
  font-weight: 600;
}

.projetlist_dropdown.dropdown button:after {
  margin-top: 5%;
  position: absolute;
  right: 1%;
  top: 1%;
}

.projectlist_dropdown_menu.dropdown-menu,
.dropdown-menu {
  width: 100%;
  max-height: 320px;
  overflow-y: scroll;
  max-width: 100%;
  overflow-x: hidden;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
  box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.6);
}

.projectlist_dropdown_menu.dropdown-menu a label {
  cursor: pointer !important;
}

.projectlist_dropdown_menu.dropdown-menu a:hover {
  background-color: #ebebeb !important;
}

.projectlist_dropdown_menu.dropdown-menu a:nth-child(even) {
  background-color: #f4f4f4;
  opacity: 1 !important;
}

.form-check-label {
  color: #140606;
  opacity: 1 !important;
}

.form-check-input {
  color: #140606;
  opacity: 1 !important;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active:hover {
  background-color: transparent !important;
}

.dropdown .dropdown-menu .dropdown-item {
  font-size: 12px !important;
  white-space: nowrap;
}

.time-validation .toast-body {
  padding: 2px;
  color: #fff;
  background: rgb(48 119 187);
}

/* .time-validation .toast-body {
  padding: 2px;
  color: #fff;
  background-color: #0c7465 !important;
  background: #159784;
  color: #fff;
  border: 1px solid #159784;
  border-radius: 5px;
  padding: 5px;
  font-size:11px
} */

#timeValidation .popover-arrow::after {
  border-top-color: rgb(48 119 187) !important;
}

.trash {
  background: url(/src/logo/trash-task.png);
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 21px;
  margin-right: 10px;
  margin-top: -3px;
  background-position: top;
}

.trash:hover {
  background-position: bottom;
}

/* Selected Task ---------------*/
.ch-selected {
  padding: 5px;
  padding-left: 15px;
}

.ch-selected-task-name {
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #545454;
  padding-bottom: 5px;
  color: #297fb8;
  margin-bottom: 20px;
  white-space: normal;
}

.selected-info-more {
  margin-bottom: 15px;
}

.ch-selected-info {
  width: 100%;
  padding-top: 5%;
}

.ch-selected-info input.timeSlot:disabled {
  background-color: #e4e1e1 !important;
  border: 1px solid#222;
}

.ch-selected-info .input-lbl {
  font-size: 11px;
  margin-bottom: 1px;
  margin-top: 7px;
  padding-left: 15px;
  white-space: nowrap;
}

.ch-selected-info .input-tag input:first-child {
  width: 60px;
  height: 26px;
  text-align: center !important;
  border: none;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-right: 1px solid #b6b6b6;
}

.ch-selected-info input[type="text"] {
  width: 160px;
  height: 26px;
  border: none;
  color: #222;
  background-color: #fff !important;
  white-space: nowrap;
  font-size: 12px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.ch-selected-info .input-tag {
  padding-left: 15px;
}

.selected-lbl-inline,
.selected-lbl-inline-right,
.selected-lbl-inline-center {
  font-size: 12px;
  font-weight: 400;
  color: #949494;
  padding-right: 5px;
  white-space: nowrap;
}

.selected-lbl-inline-right {
  text-align: right;
}

.selected-lbl-inline-center {
  text-align: center;
}

.ch-selected-info .ch-50col,
.ch-selected-info .ch-logged-center,
.ch-selected-info .ch-logged-center {
  margin: 0;
}

.ch-selected-info .ch-50col {
  width: 50%;
  padding-bottom: 10%;
}

.ch-selected-info .ch-50col div {
  margin: 0px;
}

.ch-selected-info .ch-50col div label {
  white-space: nowrap;
  font-size: 11px;
  color: #626262;
}

.ch-selected-info .ch-50col div span {
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  color: #222;
}

.ch-selected-info text {
  display: block;
  color: #626262;
  font-size: 11px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-left: 10px;
}

.ch-selected-info span.logged-hour-label-center {
  padding-left: 20px;
}

.ch-selected-info span.logged-hour-label-right {
  padding-left: 18px;
}

.ch-rem-hrs span {
  font-size: 12px;
  color: #626262;
  margin: 0;
  line-height: 12px;
  white-space: nowrap;
  margin-right: 5px;
}

.selected-lbl {
  color: #eaeaea;
  width: 100%;
}

.selected-data {
  font-size: 15px;
  color: #222;
  margin: 0;
  font-weight: 600;
  margin-top: 3px;
  white-space: nowrap;
}

.ch-rem-hrs {
  margin: 0 !important;
  margin-top: 20px !important;
  width: 100%;
}

.ch-rem-hrs input {
  width: 80px;
}

.blocking-glass-pane,
.initial-loading {
  position: absolute;
  z-index: 3200;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  cursor: wait !important;
  background-color: rgba(0, 0, 0, 0.1);

  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8 !important;
}

.blocking-glass-pane {
  background-image: url(/src/logo/checkInLoad.gif);
  background-size: 150px;
}

/* scroll bar css -------------*/
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.notify {
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  text-align: center;
  margin: 40px auto;
  /* background-color: #497ab5!important; */
  width: 142px;
  color: #fff;
  border: 2px solid #497ab5;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
}

.ch-alert-mask {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display: none;
}
.ch-alert-mask span {
  margin-top: 25%;
  display: inline-block;
  border-radius: 30px;
  border: 1px solid #fff;
  padding: 5px 20px;
}

@media (max-width: 1226px) {
  .ch-alert-mask {
    display: block;
  }
}

/* .react-calendar */

.react-calendar {
  position: relative;
  z-index: 1;
  width: 300px !important;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 10px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: "Open Sans", sans-serif !important;
  line-height: 15px;
  padding: 10px 10px 10px 10px;
}

.react-calendar__navigation__label {
  font-size: 14px;
  font-weight: 600;
}

/* .react-calendar--doubleView {
  width: 700px;
} */

/* .react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
} */

/* .react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
} */

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  /* margin: 0px 4px 0px 0px; */
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-height: 38px;
  min-width: 38px;
  background: none;
  margin: 1px 1px 1px 1px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
  /* border-radius: 5px; */
  /* padding: 5px 5px 5px 5px; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
  /* border-radius: 5px; */
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background: linear-gradient(0deg, #8299c5, #93acdb);
}

.react-calendar__month-view__days__day--neighboringMonth:hover {
  background: linear-gradient(0deg, #e8e8e8, #f8f8f8);
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  color: #000;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  /* border-radius: 6px; */
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: linear-gradient(0deg, #e8e8e8, #f8f8f8);
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  color: #000;
  /* border-radius: 6px; */
}

.react-calendar__tile--now {
  /* border-radius: 6px; */
  background: linear-gradient(0deg, #403fe2, #4a7bb6) !important;
  color: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* border-radius: 6px; */
  font-weight: bold;
  color: #fff;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.react-calendar__tile--hasActive {
  background: linear-gradient(0deg, #8299c5, #93acdb);
  /* border-radius: 6px; */
  font-weight: bold;
  color: #fff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: linear-gradient(0deg, #e8e8e8, #f8f8f8);
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  color: #333;
}

.react-calendar__tile--active {
  background: linear-gradient(0deg, #8299c5, #93acdb);
  /* border-radius: 6px; */
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: linear-gradient(0deg, #8299c5, #93acdb);
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  color: #333;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background: linear-gradient(0deg, #8299c5, #93acdb);
  color: white;
}

.react-calendar__tile--range {
  /* border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; */
  background: white;
  color: white;
}
/* .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: white;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
 }  */

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

/* .react-datepicker__day--highlighted {
  background-color: #e24131 !important;
  
} */

.react-datepicker__day--today {
  /* background-color: #159784 !important; */
  color: #fff !important;
  /* border-radius: 0.3rem !important; */
}
.react-calendar__tile .react-calendar__month-view__days__day .highlighted-day {
  background-color: #e24131;
}

.highlighted-day {
  background-color: #8299c5 !important;
}

abbr[title] {
  font-family: "Open Sans", sans-serif !important;
  text-decoration: none !important;
  cursor: pointer;
}

abbr[title]:hover {
  font-family: "Open Sans", sans-serif !important;
  text-decoration: none !important;
  cursor: pointer;
}

.react-calendar__month-view__weekdays__weekday {
  color: #000;
  font-family: "Open Sans", sans-serif !important;
  cursor: pointer;
}

/*Banner CSS*/

.ch-notif-banner .modal.show .modal-dialog {
  width: 850px !important;
}

.ch-notif-banner .modal-content {
  background-color: transparent;
}

.ch-notif-banner .bannermodal-body {
  border-radius: 12px;
  overflow: hidden;
}

.ch-notif-banner img {
  width: 100%;
}

.ch-notif-banner .btn-close {
  right: 0;
  border: 1px solid #fff;
  margin-top: -20px;
  background-color: #fff;
  padding: 7px;
  border-radius: 20px;
  margin-right: -20px;
  position: absolute;
}

.banner-content {
  padding: 0px;
  max-width: fit-content;
}

.banner.modal-title {
  margin: 0px;
}

/* Media Queries------- */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .week-nav .date {
    margin: 7px 0px;
  }

  .prev-week,
  .next-week {
    background-size: 20px;
  }

  .week-no {
    margin-left: 20px;
  }

  .nav-actions button {
    font-size: 11px;
    padding: 7px 10px;
  }

  .ch-task-container .ch-col8 {
    padding: 1.2% 0;
  }

  .week-number {
    width: 55px;
  }

  .week-number .day {
    font-size: 7.5px;
    color: #333;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .week-nav .date {
    margin: 7px 8px;
  }

  .prev-week,
  .next-week {
    background-size: 28px;
  }

  .week-no {
    margin-left: 30px;
  }

  .nav-actions button {
    font-size: 12px;
    padding: 7px 15px;
  }

  .ch-task-container .ch-col8 {
    padding: 1.2% 0;
  }

  .week-number {
    width: 58px;
  }

  .week-number .day {
    font-size: 7.5px;
    color: #333;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .week-nav .date {
    margin: 7px 15px;
  }

  .week-no {
    margin-left: 40px;
  }

  .ch-task-container .ch-col8 {
    padding: 1.2% 0.4%;
  }

  .week-number {
    width: 76px;
  }

  .week-number .day {
    font-size: 10.5px;
    color: #333;
  }
}
.center_nf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #fff;
  padding-bottom: 20%;
}


.center_nf .f04 {
  color: rgb(8, 27, 75);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1.2;
  margin: 0px;
  text-align: center;
  margin-bottom: 2%;
}
.center_nf .pnf {
  margin-top: 2%;
  font-size: 15px;
  color: #333;
  justify-content: center;
  align-items: center;
  display: flex;

}
.center_nf .pnf a{
  width: 100px;
  height: 40px;
  /* margin-right: 20px; */
  font-size: 18px;
  color: rgb(8, 27, 75);
  font-weight: normal;
  /* margin-bottom: 5px; */
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none !important;
  border: 2px solid rgb(8, 27, 75);
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  text-align: center;
  
}
.center_nf .pnf a:hover{
  background-color: rgb(8, 27, 75);
  color: #f8f8f8;
}
.inline-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.pastWkLeave-link {
  /* margin-left: 25px; */
  /* margin-top: 10px;
  padding: 1px 10px 2px 10px; */
  color: white;
  font-size: 12px;
  white-space: justify!important; /* Prevent text from wrapping to a new line unless necessary */
  overflow-wrap: break-word !important ; /* Break long words onto the next line if needed */
  word-wrap: break-word !important;
  /* height: 24px; */
  text-decoration: none !important;
  font-weight: bold; 
  padding: 4px 8px;
  /* margin-left: 10px; */
}

.pastWkLeave-link a {
  text-decoration: none !important;
}

.pastWkLeave-link:hover {
  color: white;
  font-size: 11px;
  text-decoration: underline !important;
}